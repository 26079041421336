import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";
import SnapshotProviders from "./_snapshotsProviders";

const ContentPage = () => (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      title="Automated Server Snapshots on Auto-Pilot"
      description="Schedule point-in-time snapshots for DigitalOcean Droplets, AWS EC2, Exoscale, Lightsail, Hetzner... Avoid permanent data loss and sleep better."
    />
    <Layout.Hero
      type="primary"
      title={(<>Automated <span className="text-primary">Snapshots</span> for all your servers and volumes.</>)}
      subTitle={(
        <>Schedule & automate your server & volume snapshots.<br/>
          Connect your IaaS provider. No maintenance. Prevent data loss.
        </>)}
      checkMarks={['Server snapshot', 'Storage snapshot']}
      icons={[
        <Ui.Image filename="brands/icons/digitalocean-ico.png" alt="DigitalOcean snapshot backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/hetzner-ico.png" alt="Hetzner snapshot backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/exoscale-ico.png" alt="Exoscale snapshot backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/upcloud-ico.png" alt="UpCloud snapshot backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/aws-ico.png" alt="AWS snapshot backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/amazon-lightsail-ico.png" alt="Amazon Lightsail snapshot backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/vultr-ico.png" alt="Vultr snapshot backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/scaleway-ico.png" alt="Scaleway snapshot backup" className="w-6"/>,
        <Ui.Image filename="brands/icons/linode-ico.png" alt="Linode (Akamai) snapshot backup" className="w-6"/>
      ]}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your Server & Volume Snapshot" urlAtrr={{ sb_source: "website", sb_term: "snapshot-hero" }} />)}
      illustration={(
        <Ui.Image className="" filename="simplebackups-snapshot-backup-hero.png" alt="SimpleBackups Instance & Volume Snapshot backup"/>
      )}
      withIllustrationBox={false}
    />

    <Ui.Container>
      <Sections.JoinBrandsSection />
    </Ui.Container>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                preTitle: "Optimize your snpashots",
                title: (<>Built by <span className="text-primary">experts</span>, so you don’t have to.</>),
                description: (
                  <>
                    <p>
                      Back up servers and volume on auto-pilot with scheduled snapshots.<br/>
                      Be notified, when something goes wrong, give access to your team or customers, and more.<br/>
                      IaaS solution are basic, we make sure you get the most out of them.<br/>
                      <Ui.FeatureChecks items={['Custom Schedule', 'Advanced notifications', 'Team access', 'Flexible retention', 'Centralized Backup Management', 'End-to-end backup strategy']} />
                      <Ui.Link arrow="right" to="#features">View all features</Ui.Link>
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename='simplebackups-mysql-database.png'
                  alt="PostgreSQL database backup"
                  style={{maxWidth: 450}}
                />)
              },
              Content_FeatureTrust(),
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt" id="features">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">Supported Providers</Ui.Typography>
          <p className="mb-6 text-center">Effortlessly connect your SimpleBackups account with your favorite IaaS provider. With just a single click, you can easily select the instances or volumes you want to snapshot and start protecting your data.</p>
          <div className="mt-16 text-center">
            <SnapshotProviders></SnapshotProviders>
            <div className="mt-16"><Ui.Link arrow="right" to="#features">Discover our Integration catalog</Ui.Link></div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionLearn
      title={<>Wanna learn more about Snapshot Backups?</>}
      text="Find out more and get started with our Snapshot guides"
      tags={['snapshot']}
    />

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended />
    <Sections.SectionFaq color="alt" faqs={['how_snapshot', 'snapshot_store', 'snapshot_where', 'use_for_client_2', 'use_for_other_things']}/>


  </Layout.Layout>
)

export default ContentPage
